
    import TheHeader from '@/components/layout/TheHeader.vue';
    import TheFooter from '@/components/layout/TheFooter.vue';

    export default {
        components: {
            TheHeader,
            TheFooter,
        },

        async fetch() {
            try {
                const { data: { categories } } = await this.$api.categories.getAll({ type: 'product' });
                this.categories = categories;
            } catch (error) {
                this.$handleError(error);
            }
        },

        data() {
            return {
                categories: [],
                productSidebar: [{
                    label: 'Bảng điểu khiển',
                    key: '/profile',
                }, {
                    label: 'Đơn hàng',
                    key: '/profile/orders',
                }, {
                    label: 'Chi tiết tài khoản',
                    key: '/profile/detail',
                }, {
                    label: 'Yêu thích',
                    key: '/profile/whishlist',
                }],
            };
        },

        computed: {
            currentUser() {
                return this.$auth.user || null;
            },

            currentPage() {
                return this.$route.path || '';
            },
        },

        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
        },

        methods: {
            logout() {
                this.$auth.logout();
                this.$router.push('/');
            },
        },
    };
