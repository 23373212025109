import axios from "axios";
import authService from "../services/auth.service";
const baseURL = process.env.API_HOST;
const headers = { "Content-Type": "application/json" };
class Axios {
  instance;
  interceptor;
  constructor() {
    this.accessToken = localStorage.getItem("auth._token.local") || "";
    const instance = axios.create({
      baseURL,
      headers,
    });
    // request interceptor
    instance.interceptors.request.use(
      (config) => {
        if (this.accessToken && config.headers) {
          config.headers.authorization = this.accessToken;

          return config;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // response interceptor
    const interceptor = instance.interceptors.response.use(
      (response) => {
        const { url } = response.config;
        if (url === authService.enpoint.LOGIN) {
          this.accessToken = data.data.access_token;
          localStorage.setItem("access_token", this.accessToken);
        } else if (url === authService.enpoint.LOGOUT) {
          this.accessToken = "";
          localStorage.removeItem("access_token");
        }
        return response.data;
      },
      (error) => Promise.reject(error)
    );
    this.instance = instance;
    this.interceptor = interceptor;
  }
  get Instance() {
    return this.instance;
  }
  useInterceptor() {
    if (this.interceptor === null) {
      const interceptor = this.instance.interceptors.response.use(
        (response) => response.data,
        (error) => Promise.reject(error)
      );
      this.interceptor = interceptor;
    }
  }
  ejectInterceptor() {
    if (this.interceptor !== null) {
      this.instance.interceptors.response.eject(this.interceptor);
      this.interceptor = null;
    }
  }
  get(url, config) {
    this.useInterceptor();
    return this.Instance.get(url, config);
  }
  post(url, data, config) {
    this.useInterceptor();
    return this.Instance.post(url, data, config);
  }
  put(url, data, config) {
    this.useInterceptor();
    return this.Instance.put(url, data, config);
  }
  delete(url, config) {
    this.useInterceptor();
    return this.Instance.delete(url, config);
  }
  patch(url, config) {
    this.useInterceptor();
    return this.Instance.patch(url, config);
  }
  pull(url, data, config) {
    this.ejectInterceptor();
    return this.Instance.post(url, data, config);
  }
}

const HttpClient = new Axios();
export default HttpClient;
