import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c3c5d9ee = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _90da8f48 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _77c01ecb = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _b4b0de02 = () => interopDefault(import('../pages/collaboration-guide/index.vue' /* webpackChunkName: "pages/collaboration-guide/index" */))
const _60b7fefa = () => interopDefault(import('../pages/complaint-handling-policy/index.vue' /* webpackChunkName: "pages/complaint-handling-policy/index" */))
const _0c4834ab = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _7eeb42d2 = () => interopDefault(import('../pages/guide-to-goods-inspection/index.vue' /* webpackChunkName: "pages/guide-to-goods-inspection/index" */))
const _dadc5698 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _27944a02 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _b04d328c = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _6d6dfa18 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _1adfbb6c = () => interopDefault(import('../pages/purchase/index.vue' /* webpackChunkName: "pages/purchase/index" */))
const _05451a89 = () => interopDefault(import('../pages/purchase-policy/index.vue' /* webpackChunkName: "pages/purchase-policy/index" */))
const _3e8a5436 = () => interopDefault(import('../pages/refund-instructions/index.vue' /* webpackChunkName: "pages/refund-instructions/index" */))
const _2574117c = () => interopDefault(import('../pages/shipping-policy/index.vue' /* webpackChunkName: "pages/shipping-policy/index" */))
const _6f032d7c = () => interopDefault(import('../pages/shopping-guide/index.vue' /* webpackChunkName: "pages/shopping-guide/index" */))
const _0fab29c2 = () => interopDefault(import('../pages/profile/detail.vue' /* webpackChunkName: "pages/profile/detail" */))
const _0e7e5b54 = () => interopDefault(import('../pages/profile/download.vue' /* webpackChunkName: "pages/profile/download" */))
const _64104bb3 = () => interopDefault(import('../pages/profile/orders.vue' /* webpackChunkName: "pages/profile/orders" */))
const _79f3decd = () => interopDefault(import('../pages/profile/whishlist.vue' /* webpackChunkName: "pages/profile/whishlist" */))
const _55eb21c4 = () => interopDefault(import('../pages/blogs/_id.vue' /* webpackChunkName: "pages/blogs/_id" */))
const _6a25cfa2 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _6407384c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _c3c5d9ee,
    name: "about-us"
  }, {
    path: "/blogs",
    component: _90da8f48,
    name: "blogs"
  }, {
    path: "/cart",
    component: _77c01ecb,
    name: "cart"
  }, {
    path: "/collaboration-guide",
    component: _b4b0de02,
    name: "collaboration-guide"
  }, {
    path: "/complaint-handling-policy",
    component: _60b7fefa,
    name: "complaint-handling-policy"
  }, {
    path: "/contact",
    component: _0c4834ab,
    name: "contact"
  }, {
    path: "/guide-to-goods-inspection",
    component: _7eeb42d2,
    name: "guide-to-goods-inspection"
  }, {
    path: "/login",
    component: _dadc5698,
    name: "login"
  }, {
    path: "/privacy-policy",
    component: _27944a02,
    name: "privacy-policy"
  }, {
    path: "/product",
    component: _b04d328c,
    name: "product"
  }, {
    path: "/profile",
    component: _6d6dfa18,
    name: "profile"
  }, {
    path: "/purchase",
    component: _1adfbb6c,
    name: "purchase"
  }, {
    path: "/purchase-policy",
    component: _05451a89,
    name: "purchase-policy"
  }, {
    path: "/refund-instructions",
    component: _3e8a5436,
    name: "refund-instructions"
  }, {
    path: "/shipping-policy",
    component: _2574117c,
    name: "shipping-policy"
  }, {
    path: "/shopping-guide",
    component: _6f032d7c,
    name: "shopping-guide"
  }, {
    path: "/profile/detail",
    component: _0fab29c2,
    name: "profile-detail"
  }, {
    path: "/profile/download",
    component: _0e7e5b54,
    name: "profile-download"
  }, {
    path: "/profile/orders",
    component: _64104bb3,
    name: "profile-orders"
  }, {
    path: "/profile/whishlist",
    component: _79f3decd,
    name: "profile-whishlist"
  }, {
    path: "/blogs/:id",
    component: _55eb21c4,
    name: "blogs-id"
  }, {
    path: "/product/:id",
    component: _6a25cfa2,
    name: "product-id"
  }, {
    path: "/",
    component: _6407384c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
