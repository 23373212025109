// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import HttpClient from '@/libs/axios';

const discountService = {
    enpoint: {
        SEND_DISCOUNT: 'u/discount_registers?origin=pet-house.vn',
    },
    sendDiscount(email) {
        return HttpClient.post(this.enpoint.SEND_DISCOUNT, email);
    },
};
export default discountService;
