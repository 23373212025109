
    import VueSlickCarousel from 'vue-slick-carousel';
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
    import 'vue-slick-carousel/dist/vue-slick-carousel.css';

    export default {
        components: {
            VueSlickCarousel,
        },

        props: {
            data: {
                type: Array,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                dataCarousel: this.data,
                settings: {
                    speed: 600,
                    autoplaySpeed: 1000,
                    dots: false,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    autoplay: false,
                },
            };
        },

        computed: {
        },

        watch: {
            data: {
                handler() {
                    this.dataCarousel = this.data || [];
                    this.$forceUpdate();
                },
                deep: true,
            },
        },

        methods: {
            afterChangeSlide() {
                this.titleAnimate = false;
                setTimeout(() => {
                    this.titleAnimate = true;
                }, 0);
            },
        },
    };
