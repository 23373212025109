
    import { defineComponent, ref } from 'vue';
    import { mapGetters } from 'vuex';
    import { currencyVN } from '@/utils/currency';
    import SliderHeader from '@/components/shared/Slider.vue';
    import SidebarMobile from './SidebarMobile.vue';
    import LoginForm from '../LoginForm.vue';
    import ModalRegister from './ModalRegister.vue';
    import ModelFade from '../ModelFade.vue';
    import cartService from '../../services/cart.service';

    export default defineComponent({
        components: {
            SidebarMobile,
            LoginForm,
            ModalRegister,
            ModelFade,
            SliderHeader,
        },
        props: {
            categories: {
                type: Array,
                default: () => [],
            },
            setting: {
                type: Object,
                default: () => {},
            },
        },

        setup() {
            const visibleConfirm = ref(false);
            const showModalConfirm = () => {
                visibleConfirm.value = true;
            };

            const handleOk = () => {
                visibleConfirm.value = false;
            };
            return {
                visibleConfirm,
                showModalConfirm,
                handleOk,
            };
        },
        data() {
            return {
                visible: false,
                visibleSearch: false,
                modalShow: false,
                cartDelete: { id: '', color: '' },
                currencyVN,
                cartService,
            };
        },
        computed: {
            ...mapGetters('products', ['useCart']),
            ...mapGetters('products', ['useWhishlist']),
        },

        methods: {
            showDrawer() {
                this.visible = true;
            },
            onClose() {
                this.visible = false;
            },
            showModal() {
                this.modalShow = true;
                document.body.classList.add('showModal');
            },
            hiddenModal() {
                this.modalShow = false;
                document.body.classList.remove('showModal');
            },
            onCloseCart(path) {
                this.visible = false;
                this.$router.push(path);
            },
            onCloseRedirectCart() {
                this.visible = false;
                this.$router.push('/cart');
            },
            handleRemoveCart(id, color) {
                this.onClose();
                this.showModalConfirm();
                this.cartDelete = { id, color };
            },
            handleCancel() {
                this.handleOk();
                this.showDrawer();
                this.cartDelete = false;
            },
            handleDelete() {
                this.handleOk();
                this.showDrawer();
                const cart = this.cartDelete;
                const isDelete = Object.values(cart).every((item) => item !== '');
                if (isDelete) {
                    this.$store.dispatch('products/removeProductToCart', { id: cart.id, color: cart.color });
                }
                this.cartDelete = {};
            },
            async handleLogout() {
                await this.$auth.logout();
                window.location.href = '/';
            },
        },
    });
