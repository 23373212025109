// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import HttpClient from '@/libs/axios';

const cartService = {
    enpoint: {
        GET_ALL: 'u/carts?origin=pet-house.vn',
        ADD: 'u/carts?origin=pet-house.vn',
    },
    getAll() {
        return HttpClient.get(this.enpoint.GET_ALL);
    },
    add(body) {
        return HttpClient.post(this.enpoint.ADD, body);
    },
};
export default cartService;
