import Auth from '@/api/auth';
import Orders from '@/api/orders';
import Categories from '@/api/categories';
import Rates from '@/api/rates';
import Uploaders from '@/api/uploaders';
import Carts from '@/api/carts';
import Banners from '@/api/banners';
import Products from '@/api/products';
import Policies from '@/api/policies';
import Medias from '@/api/medias';
import Blogs from '@/api/blogs';

export default (context, inject) => {
    // Initialize API factories
    const factories = {
        auth: Auth(context.$axios),
        categories: Categories(context.$axios),
        rates: Rates(context.$axios),
        uploaders: Uploaders(context.$axios),
        orders: Orders(context.$axios),
        carts: Carts(context.$axios),
        banners: Banners(context.$axios),
        products: Products(context.$axios),
        policies: Policies(context.$axios),
        medias: Medias(context.$axios),
        blogs: Blogs(context.$axios),
    };

    inject('api', factories);
};
