
    import { defineComponent } from 'vue';

    export default defineComponent({
        data() {
            return {
                menuList: [
                    { name: 'Trang chủ', path: '/' },
                    { name: 'Sản phẩm', path: '/product' },
                    { name: 'Về chúng tôi', path: '/about-us' },
                    { name: 'Liên hệ', path: '/contact' },
                ],
                categories: [],
                visible: false,
            };
        },
        async mounted() {
            await this.fetchData();
        },
        methods: {
            showDrawer() {
                this.visible = true;
            },
            onClose() {
                this.visible = false;
            },
            redirectPage(path) {
                this.$router.push(path);
                this.onClose();
            },
            async fetchData() {
                try {
                    const res = await this.$axios.get('u/categories?type=product&origin=pet-house.vn');
                    this.categories = res.data.data.categories;
                } catch (error) {
                    console.log(error);
                }
            },
        },
    });
