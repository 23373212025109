export const state = () => ({
});

export const getters = {
};

export const actions = {
    nuxtServerInit() {
    },
};
