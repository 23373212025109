
    import { mapState } from 'vuex';
    import { notification } from 'ant-design-vue';
    import { defineComponent } from 'vue';
    import authService from '@/services/auth.service';

    export default defineComponent({

        data() {
            return {
                email: '',
                password: '',
                errorEmail: '',
                errorPassword: '',
                authService,
                history: '/',
            };
        },
        computed: {
            ...mapState('path', ['path']),
        },

        watch: {
            email(prev, next) {
                this.validateEmail(next);
            },
            '$router.path': {
                handler() {
                    this.history = this.$router.history._startLocation;
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            validateEmail(value) {
                if (value === '') {
                    this.errorEmail = 'Vui lòng nhập email';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(value)) {
                    this.errorEmail = 'Vui lòng nhập đúng email';
                } else {
                    this.errorEmail = '';
                }
            },
            validatePassword(value) {
                if (value === '') {
                    this.errorPassword = 'Vui lòng nhập password';
                } else {
                    this.errorPassword = '';
                }
            },
            onBlurEmail(e) {
                this.validateEmail(e.target.value);
            },
            onBlurPassword(e) {
                this.validatePassword(e.target.value);
            },
            onSubmit() {
                const { email, password } = this;
                this.validateEmail(email);
                this.validatePassword(password);
                if (this.errorPassword !== '' || this.errorEmail !== '') {
                    return;
                }
                this.handleLogin();
            },
            async handleLogin() {
                const { email, password } = this;
                await this.$auth.loginWith('local', {
                    data: { email, password, origin: 'pet-house.vn' },
                }).then(async () => {
                    this.openNotifiSuccess('Đăng nhập thành công');
                    this.$router.push(`${this.path && this.path !== '/login' ? this.path : '/'}`);
                }).catch(() => {
                    this.openNotifiError('Đăng nhập thất bại');
                });
            },
        },
        setup() {
            const openNotifiError = (message) => {
                notification.error({
                    message: 'Thất bại',
                    description: message,
                });
            };
            const openNotifiSuccess = (message) => {
                notification.success({
                    message: 'Thành công',
                    description: message,
                });
            };
            return {
                openNotifiError,
                openNotifiSuccess,
            };
        },
    });
