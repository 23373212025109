// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import HttpClient from '@/libs/axios';

const origin = 'pet-house.vn';
const authService = {
    enpoint: {
        LOGIN: '/u/sessions/login?origin=pet-house.vn',
        REGISTER: '/u/sessions?origin=pet-house.vn',
        UPDATE: '/u/sessions?origin=pet-house.vn',
        SETTING: '/u/settings?origin=pet-house.vn',
        FORGOT: '/u/sessions/forgot_password',
        CHANGE: '/u/sessions/change_password',
        LOGOUT: '',
    },
    login(body) {
        return HttpClient.post(this.enpoint.LOGIN, {
            ...body,
            origin,
        });
    },
    register(body) {
        return HttpClient.post(this.enpoint.REGISTER, {
            ...body,
            origin,
        });
    },
    update(body) {
        return HttpClient.patch(this.enpoint.UPDATE, {
            ...body,
            origin,
        });
    },
    setting() {
        return HttpClient.get(this.enpoint.SETTING);
    },
    forgotPassword() {
        return HttpClient.get(this.enpoint.FORGOT);
    },
    changePassword(body) {
        return HttpClient.patch(this.enpoint.CHANGE, {
            ...body,
            origin,
        });
    },
};
export default authService;
