
    import { defineComponent } from 'vue';
    import { notification } from 'ant-design-vue';
    import { currencyVN } from '@/utils/currency';
    import discountService from '@/services/discount_register.service';

    export default defineComponent({
        props: {
            hiddenModal: Function,
            modalShow: Boolean,
        },
        data() {
            return {
                currencyVN,
                discountService,
                email: '',
                errorEmail: '',
            };
        },
        methods: {
            validateEmail(value) {
                if (value === '') {
                    this.errorEmail = 'Vui lòng nhập email';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(value)) {
                    this.errorEmail = 'Vui lòng nhập đúng email';
                } else {
                    this.errorEmail = '';
                }
            },
            onBlurEmail(e) {
                this.validateEmail(e.target.value);
            },
            async onSubmit() {
                const { email } = this;
                this.validateEmail(email);
                if (this.errorEmail !== '') {
                    return;
                }
                try {
                    await this.discountService.sendDiscount({ email, domain: 'pet-house.vn' });
                    this.openNotifiSuccess('Đăng ký thành công');
                } catch (error) {
                    this.openNotifiError('Đăng ký thất bại,vui lòng đăng nhập');
                }
            },
        },
        setup() {
            const openNotifiError = (message) => {
                notification.error({
                    message: 'Thất bại',
                    description: message,
                });
            };
            const openNotifiSuccess = (message) => {
                notification.success({
                    message: 'Thành công',
                    description: message,
                });
            };
            return {
                openNotifiError,
                openNotifiSuccess,
            };
        },
    });
