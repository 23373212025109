export const HomeDog = () => import('../../components/HomeDog.vue' /* webpackChunkName: "components/home-dog" */).then(c => wrapFunctional(c.default || c))
export const InputNumber = () => import('../../components/InputNumber.vue' /* webpackChunkName: "components/input-number" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const ModelFade = () => import('../../components/ModelFade.vue' /* webpackChunkName: "components/model-fade" */).then(c => wrapFunctional(c.default || c))
export const Verify = () => import('../../components/Verify.vue' /* webpackChunkName: "components/verify" */).then(c => wrapFunctional(c.default || c))
export const CartInputNumberCart = () => import('../../components/cart/InputNumberCart.vue' /* webpackChunkName: "components/cart-input-number-cart" */).then(c => wrapFunctional(c.default || c))
export const ContactCollapse = () => import('../../components/contact/Collapse.vue' /* webpackChunkName: "components/contact-collapse" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/contact/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const HomeBannerProduct = () => import('../../components/home/BannerProduct.vue' /* webpackChunkName: "components/home-banner-product" */).then(c => wrapFunctional(c.default || c))
export const HomeCustomerSay = () => import('../../components/home/CustomerSay.vue' /* webpackChunkName: "components/home-customer-say" */).then(c => wrapFunctional(c.default || c))
export const HomeGallery = () => import('../../components/home/Gallery.vue' /* webpackChunkName: "components/home-gallery" */).then(c => wrapFunctional(c.default || c))
export const LayoutBanner = () => import('../../components/layout/Banner.vue' /* webpackChunkName: "components/layout-banner" */).then(c => wrapFunctional(c.default || c))
export const LayoutModalRegister = () => import('../../components/layout/ModalRegister.vue' /* webpackChunkName: "components/layout-modal-register" */).then(c => wrapFunctional(c.default || c))
export const LayoutSidebarMobile = () => import('../../components/layout/SidebarMobile.vue' /* webpackChunkName: "components/layout-sidebar-mobile" */).then(c => wrapFunctional(c.default || c))
export const LayoutTheFooter = () => import('../../components/layout/TheFooter.vue' /* webpackChunkName: "components/layout-the-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutTheHeader = () => import('../../components/layout/TheHeader.vue' /* webpackChunkName: "components/layout-the-header" */).then(c => wrapFunctional(c.default || c))
export const FiltersSelectRemote = () => import('../../components/filters/SelectRemote.vue' /* webpackChunkName: "components/filters-select-remote" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/product/Product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const ProductSkeleton = () => import('../../components/product/Skeleton.vue' /* webpackChunkName: "components/product-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProductViewProduct = () => import('../../components/product/ViewProduct.vue' /* webpackChunkName: "components/product-view-product" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailCarousel = () => import('../../components/product-detail/Carousel.vue' /* webpackChunkName: "components/product-detail-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailSkeletonDetailProduct = () => import('../../components/product-detail/SkeletonDetailProduct.vue' /* webpackChunkName: "components/product-detail-skeleton-detail-product" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailZoomImages = () => import('../../components/product-detail/ZoomImages.vue' /* webpackChunkName: "components/product-detail-zoom-images" */).then(c => wrapFunctional(c.default || c))
export const ProfileForm = () => import('../../components/profile/Form.vue' /* webpackChunkName: "components/profile-form" */).then(c => wrapFunctional(c.default || c))
export const ProfileOrderDialog = () => import('../../components/profile/OrderDialog.vue' /* webpackChunkName: "components/profile-order-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProfileOrderList = () => import('../../components/profile/OrderList.vue' /* webpackChunkName: "components/profile-order-list" */).then(c => wrapFunctional(c.default || c))
export const ProfileProductCard = () => import('../../components/profile/ProductCard.vue' /* webpackChunkName: "components/profile-product-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileUpdatePassword = () => import('../../components/profile/UpdatePassword.vue' /* webpackChunkName: "components/profile-update-password" */).then(c => wrapFunctional(c.default || c))
export const SharedConfirmDialog = () => import('../../components/shared/ConfirmDialog.vue' /* webpackChunkName: "components/shared-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const SharedSlider = () => import('../../components/shared/Slider.vue' /* webpackChunkName: "components/shared-slider" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
