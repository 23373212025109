
    import TheHeader from '@/components/layout/TheHeader.vue';
    import TheFooter from '@/components/layout/TheFooter.vue';
    import authService from '@/services/auth.service';

    export default {
        components: {
            TheHeader,
            TheFooter,
        },

        async fetch() {
            try {
                const { data: { categories } } = await this.$api.categories.getAll({ type: 'product' });
                this.categories = categories;
            } catch (error) {
                this.$handleError(error);
            }
        },

        data() {
            return {
                categories: [],
                setting: {},
                authService,
            };
        },

        computed: {},

        watch: {},

        created() {
            this.authService.setting().then((res) => {
                this.setting = res.data.setting;
            });
        },
    };
