export const state = () => ({
    banners: [],
});

export const getters = {
};

export const mutations = {
    SET_STATE(state, { prop, data }) {
        state[prop] = data;
    },
};

export const actions = {
    async fetchAll({ commit }) {
        const { data: { banners } } = await this.$api.banners.getAll();
        commit('SET_STATE', { prop: 'banners', data: banners || [] });
    },
};
