import _cloneDeep from 'lodash/cloneDeep';

const initialLS = (key) => {
    const item = localStorage.getItem(key);
    return !item ? [] : JSON.parse(item);
};
export const emptyState = () => ({
    products: [],
    product: null,
    cart: initialLS('cart'),
    whishlist: initialLS('whishlist'),
    toast: {
        status: false,
        type: 'success',
        product: {},
    },
});
export const state = emptyState;

export const getters = {
    products(state) {
        return state.products;
    },
    product(state) {
        return state.product;
    },
    useCart(state) {
        const cart = state.cart;
        const whishlist = state.whishlist;
        const sumPrice = cart.map((item) => +item.total).reduce((a, b) => a + b, 0) || 0;
        const countCart = cart.map((item) => +item.count).reduce((a, b) => a + b, 0) || 0;
        return {
            cart,
            sumPrice,
            countCart,
            whishlist,
        };
    },
};

export const mutations = {
    SET_STATE(state, { prop, data }) {
        state[prop] = data;
    },
    SET_PAGINATION(state, payload) {
        state.pagination = payload;
    },
    RESET_STATE(state) {
    // eslint-disable-next-line no-unused-vars
        state = emptyState;
    },
    SAVE_LOCAL(_, payload) {
        localStorage.setItem('cart', JSON.stringify(payload));
    },
    SAVE_WISHLISH(_, payload) {
        localStorage.setItem('whishlist', JSON.stringify(payload));
    },
    GET_LOCAL(state, payload) {
        state.cart = payload;
    },
};

export const actions = {
    async fetchAll({ commit }) {
        const { data: { products } } = await this.$api.products.getAll();
        commit('SET_STATE', { prop: 'products', data: products || [] });
    },

    async fetchDetail({ commit }, payload) {
        const { data: { product } } = await this.$api.products.getDetail(payload);
        commit('SET_STATE', { prop: 'product', data: product });
    },

    addToWhishlist(context, payload) {
        const cacheWhishlist = _cloneDeep(context.state.whishlist);
        const indexProductInCart = cacheWhishlist.findIndex((item) => item._id === payload._id);
        const productInCart = cacheWhishlist[indexProductInCart];

        if (indexProductInCart === -1 && !productInCart) {
            cacheWhishlist.unshift(payload);
        } else {
            cacheWhishlist.splice(indexProductInCart, 1);
        }
        context.commit('SET_STATE', { prop: 'whishlist', data: cacheWhishlist });
        context.commit('SAVE_WISHLISH', cacheWhishlist);
    },

    addProductToCart(context, payload) {
        const cacheCart = _cloneDeep(context.state.cart);
        const indexProductInCart = cacheCart.findIndex((item) => {
            if (item._id === payload._id) {
                if (item.color !== payload.color) {
                    return false;
                }
                return true;
            }
            return false;
        });
        const productInCart = cacheCart[indexProductInCart];

        if (indexProductInCart === -1 && !productInCart) {
            cacheCart.unshift({ ...payload, total: payload.price * 1 });
        } else {
            const countProduct = +productInCart.count + +payload.count;
            cacheCart[indexProductInCart] = {
                ...productInCart,
                count: +productInCart.count + +payload.count,
                total: +productInCart.price * +countProduct,
            };
        }
        context.commit('SET_STATE', { prop: 'cart', data: cacheCart });
        context.commit('SAVE_LOCAL', cacheCart);
    },

    removeProductToCart(context, payload) {
        const cacheCart = _cloneDeep(context.state.cart);
        const indexProductInCart = cacheCart.findIndex((item) => {
            if (item._id === payload.id) {
                if (item.color !== payload.color) {
                    return false;
                }
                return true;
            }
            return false;
        });
        cacheCart.splice(indexProductInCart, 1);
        context.commit('SET_STATE', { prop: 'cart', data: cacheCart });
        context.commit('SAVE_LOCAL', cacheCart);
    },
    clearCart(context) {
        context.commit('SET_STATE', { prop: 'cart', data: [] });
        context.commit('SAVE_LOCAL', []);
    },
    changeCountProduct(context, { id, count }) {
        const cacheCart = _cloneDeep(context.state.cart);
        const indexProductInCart = cacheCart.findIndex((item) => item._id === id);
        const productInCart = cacheCart[indexProductInCart];
        cacheCart[indexProductInCart] = {
            ...productInCart,
            count: +count,
            total: +productInCart.price * +productInCart.count,
        };
        context.commit('SET_STATE', { prop: 'cart', data: cacheCart });
        context.commit('SAVE_LOCAL', cacheCart);
    },

    resetState({ commit }) {
        commit('RESET_STATE');
    },
};
