export const state = () => ({
    policy: {},
});

export const getters = {
};

export const mutations = {
    SET_STATE(state, { prop, data }) {
        state[prop] = data;
    },
};

export const actions = {
    async fetchDetail({ commit }, payload) {
        const { data: { page } } = await this.$api.policies.getDetail(payload);
        commit('SET_STATE', { prop: 'policy', data: page });
    },
};
