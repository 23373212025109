const initialLS = (key) => {
    const item = localStorage.getItem(key);
    return !item ? {} : JSON.parse(item);
};

export const state = () => ({
    path: '',
    checkoutForm: initialLS('checkoutForm'),
});

export const getters = {
};

export const mutations = {
    SET_STATE(state, { prop, data }) {
        state[prop] = data;
    },
};

export const actions = {
    setPath({ commit }, payload) {
        commit('SET_STATE', { prop: 'path', data: payload });
    },

    setForm({ commit }, payload) {
        localStorage.setItem('checkoutForm', JSON.stringify(payload));
        commit('SET_STATE', { prop: 'checkoutForm', data: payload });
    },

    emptyState({ commit }) {
        commit('SET_STATE', { prop: 'path', data: null });
    },
};
